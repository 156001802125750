import Header from "../headerComponents/Header";
import About from "../aboutPage/About";
import Footer from "../frontPage/footerComponents/Footer";

export default function ProductsPage(){
    return(
        <>
        <Header headerDes="Обо мне" description=""/>
        <About />
        <Footer/>
        </>
)
}