// ProfileDisplay.jsx
import "./profile.css";
import { useDispatch, useSelector } from "react-redux";
import { useLogoutMutation } from "../../slices/usersApiSlice";
import { logout } from "../../slices/authSlice";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "../headerComponents/Navbar";
import { useProfileMutation } from "../../slices/usersApiSlice";
import { useState } from "react";
import { toast } from "react-toastify";
import { setCredentials } from "../../slices/authSlice";
import Loader from "../Loader";
import { useGetMyOrdersQuery } from "../../slices/ordersApiSlice";
import { useEffect } from "react";

export default function ProfileDisplay() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutApiCall] = useLogoutMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [updateProfile, { isLoading: loadingUpdateProfile }] =
    useProfileMutation();

  // Fetch orders associated with the logged-in user's ID
  const {
    data: orders,
    isLoading: ordersLoading,
    error: ordersError,
  } = useGetMyOrdersQuery();

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name);
      setEmail(userInfo.email);
    }
  }, [userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Пароли не совпадают");
    } else {
      try {
        const res = await updateProfile({
          _id: userInfo._id,
          name,
          email,
          password,
        }).unwrap();
        dispatch(setCredentials({ ...res }));
        toast.success("Профиль успешно обновлен");
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      localStorage.removeItem("userInfo"); // Remove the profile from local storage
      navigate("/"); // Redirect to the home page
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="userProfile__background">
      <Navbar />
      <div className="userProfile__container">
        <h1>Мой профайл</h1>
        {userInfo && userInfo.isAdmin ? (
          <div className="userProfile__adminButton">
            <Link to={`/admin/orderlist`}>
              <button>
                Нажмите здесь, чтобы получить доступ к порталу администратора
              </button>
            </Link>
          </div>
        ) : null}
        <div className="userProfile__miniContainer">
          <form className="userProfile__updateInfo" onSubmit={submitHandler}>
            <h2>Обновить профайл</h2>
            <p>Имя</p>
            <input
              type="name"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
            <p>е-мейл</p>
            <input
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <p>Пароль</p>
            <input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
            <p>Подтвердите пароль</p>
            <input
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></input>
            <br></br>
            <button type="submit">изменить</button>
            {loadingUpdateProfile && <Loader />}
          </form>
          <div className="userProfile__displayOrders">
            <h2>мои kонсультации</h2>
            {ordersLoading ? (
              <Loader />
            ) : ordersError ? (
              <div>Ошибка загрузки заказов</div>
            ) : orders && orders.length > 0 ? (
              orders.map((order) => (
                <ul key={order._id} className="userProfile__ordersList">
                  <li>{order._id}</li>
                  <li>£{order.itemsPrice}</li>
                  <li>
                    {order.isPaid ? (
                      <p className="userProfile__paidOrder">Оплаченный</p>
                    ) : (
                      <p className="userProfile__unpaidOrder">
                        не оплачено
                      </p>
                    )}
                  </li>
                  <li>
                    <Link to={`/order/${order._id}`}>
                      <button>Подробности</button>
                    </Link>
                  </li>
                </ul>
              ))
            ) : (
              <p>Нет доступных заказов</p>
            )}
          </div>
        </div>
        <div className="userProfile__logoutSection">
          <hr></hr>
          <button onClick={logoutHandler}>Выйти</button>
        </div>
      </div>
    </div>
  );
}
