import Navbar from "../headerComponents/Navbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./customerInfoForm.css";
import { saveCustomerInformation } from "../../slices/cartSlice";
import { useDispatch, useSelector } from "react-redux";

export default function CustomerInfoForm() {
  const cart = useSelector((state) => state.cart);
  const { customerInformation } = cart;

  const [dateOfBirth, setDateOfBirth] = useState(
    customerInformation?.dateOfBirth || ""
  );
  const [timeOfBirth, setTimeOfBirth] = useState(
    customerInformation?.timeOfBirth || ""
  );
  const [cityOfBirth, setCityOfBirth] = useState(
    customerInformation?.cityOfBirth || ""
  );
  const [cityOfResidence, setCityOfResidence] = useState(
    customerInformation?.cityOfResidence || ""
  );
  const [contactEmail, setContactEmail] = useState(
    customerInformation?.contactEmail || ""
  );
  const [preference, setPreference] = useState(
    customerInformation?.preference || ""
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      !dateOfBirth ||
      !timeOfBirth ||
      !cityOfBirth ||
      !cityOfResidence ||
      !contactEmail ||
      !preference
    ) {
      toast.error("Пожалуйста, заполните все обязательные поля");
    } else {
      dispatch(
        saveCustomerInformation({
          dateOfBirth,
          timeOfBirth,
          cityOfBirth,
          cityOfResidence,
          contactEmail,
          preference,
        })
      );
      navigate("/payment");
    }
  };

  return (
    <div className="customerInfoForm__background">
      <Navbar />
      <div className="customerInfoForm__formSection">
        <form onSubmit={submitHandler} className="customerInfoForm__form">
          <h1>Информация о вас</h1>
          <div className="customerInfoForm__disclaimer">
            <p>
              Предоставление точной информации важно для точного
              прогнозирования. Мы свяжемся с вами по предоставленной электронной
              почте для дополнительной информаци (если нужно будет).
            </p>
          </div>
          <p>Дата рождения</p>
          <input
            type="date"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
          />
          <p>Время рождения</p>
          <input
            type="time"
            value={timeOfBirth}
            onChange={(e) => setTimeOfBirth(e.target.value)}
          />
          <p>Место рождения (город и страна)</p>
          <input
            type="text"
            placeholder="Место рождения (город и страна)"
            value={cityOfBirth}
            onChange={(e) => setCityOfBirth(e.target.value)}
          />
          <p>Город проживания</p>
          <input
            type="text"
            placeholder="Город проживания"
            value={cityOfResidence}
            onChange={(e) => setCityOfResidence(e.target.value)}
          />
          <p>Э-мейл для связи</p>
          <input
            type="email"
            placeholder="Э-мейл для связи"
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
          />
          <p>Дополнительная информация</p>
          <textarea
            placeholder="Дополнительная информация            "
            value={preference}
            onChange={(e) => setPreference(e.target.value)}
          />
          <br></br>
          <button type="submit">Продолжить</button>
        </form>
      </div>
    </div>
  );
}
