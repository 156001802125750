import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainPage from "./Components/pages/MainPage";
import ProductsPage from './Components/pages/ProductsPage';
import CartPage from './Components/pages/CartPage';
import LoginPage from './Components/pages/LoginPage';
import ProfilePage from './Components/pages/ProfilePage';
import SignupPage from './Components/pages/SignupPage';
import InfoPage from './Components/pages/InfoPage';
import PrivateRoute from './Components/PrivateRoute';
import PaymentPage from './Components/pages/PaymentPages';
import CreateOrder from './Components/createOrderPage/createOrder';
import OrderPage from './Components/pages/OrderPage';
import AdminRoute from './Components/adminPage/AdminRoute';
import AdminPage from './Components/pages/AdminPage';
import AboutPage from './Components/pages/AboutPage'

export default function App() {
    return (
      <>
        <Router>
        <Routes>
          <Route index={true} path="/" element={<MainPage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path='/cart' element={<CartPage/>} />
          <Route path='/login' element={<LoginPage/>} />
          <Route path='/profile' element={<ProfilePage/>}/>
          <Route path='/register' element={<SignupPage/>}/>
          <Route path='/aboutme' element={<AboutPage/>}/>
          
          
          <Route path='' element={<PrivateRoute/>}>
            <Route path='/information' element={<InfoPage/>}/>
            <Route path='/payment' element={<PaymentPage/>}/> 
            <Route path='/placeorder' element={<CreateOrder/>}/>
            <Route path='/order/:id' element={<OrderPage/>}/>
          </Route>

          <Route path='' element={<AdminRoute/>}>
              <Route path='/admin/orderlist' element={<AdminPage/>}/>
          </Route>
        </Routes>
      </Router>
      <ToastContainer/>
      </>

    );
  }  