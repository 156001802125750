// authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { useNavigate } from 'react-router-dom';

const initialState = {
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
    },
    logout: (state) => {
      state.userInfo = null;
      localStorage.removeItem('userInfo');
    },
  },
});

export const logoutUser = () => async (dispatch) => {
  try {
    const [logoutApiCall] = useLogoutMutation();
    await logoutApiCall().unwrap();
    dispatch(logout());
    localStorage.removeItem('userInfo');
    const navigate = useNavigate();
    navigate('/');
  } catch (err) {
    console.log(err);
  }
};

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;
