import Message from "../Message";
import Loader from "../Loader";
import { useDeleteUserMutation, useGetUsersQuery } from "../../slices/usersApiSlice";
import { toast } from "react-toastify";

export default function UsersDisplay(){

    const { data: users, refetch, isLoading, error } = useGetUsersQuery();

    const [deleteUser] = useDeleteUserMutation();
  
    const deleteHandler = async (id) => {
      if (window.confirm('Are you sure')) {
        try {
          await deleteUser(id);
          refetch();
        } catch (err) {
          toast.error(err?.data?.message || err.error);
        }
      }
    };

    return(
        <div className="adminPage__userDisplay">
                  <h1>клиенты</h1>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>ИМЯ</th>
              <th>е-мейл</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user._id}>
                <td>{user._id}</td>
                <td>{user.name}</td>
                <td>
                  <a href={`mailto:${user.email}`}>{user.email}</a>
                </td>
                <td>
                <button onClick={() => deleteHandler(user._id)}>
                Удалить
                </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
        </div>
    )

}