import "./FrontStyles.css";
import {
  HeartFill, // Suggested icon for "Консультация по Натальной Карте"
  SunFill, // Suggested icon for "UЖивите Лучшей Жизнью"
  CalendarCheckFill, // Suggested icon for "Годовой Прогноз"
  PeopleFill, // Suggested icon for "Детский Гороскоп"
  CalendarEventFill, // Suggested icon for "Выбор Идеальных Дат"
  ClockFill, // Suggested icon for "Ректификация Времени Рождения"
  StarFill, // Suggested icon for "Раскройте Таланты"
  LifePreserver, // Suggested icon for "Путь Жизни и Карма"
  ChatFill, // Suggested icon for "Понимание Отношений"
  CompassFill, // Suggested icon for "Сглаживание Переходов"
} from "react-bootstrap-icons";

export default function Cards() {
  return (
    <div className="Cards">
      <h2>С какими проблемами я могу помочь</h2>
      <p>

      </p>
      <div className="Cards__cardItemContainer">
        <div className="Cards__cardItem">
          <HeartFill className="Cards__icons" />
          <p>
            <span className="Cards__span">Консультация по Натальной Карте: </span> pазгадайте свою уникальную личность, сильные стороны и вызовы.
          </p>
        </div>
        <div className="Cards__cardItem">
          <SunFill className="Cards__icons" />
          <p>
          <span className="Cards__span">Живите Лучшей Жизнью: </span> pуководство по оптимизации вашего жизненного пути.
          </p>
        </div>
        <div className="Cards__cardItem">
          <CalendarCheckFill className="Cards__icons" />
          <p>
          <span className="Cards__span"> Годовой Прогноз:</span> навигация по году с пониманием важных сфер жизни.
          </p>
        </div>
        <div className="Cards__cardItem">
          <PeopleFill className="Cards__icons" />
          <p>
          <span className="Cards__span"> Детский Гороскоп: </span> понимание характера, талантов и потребностей вашего ребенка.
          </p>
        </div>
        <div className="Cards__cardItem">
          <CalendarEventFill className="Cards__icons" />
          <p>
          <span className="Cards__span"> Выбор Идеальных Дат: </span> найдите лучшее время для свадеб, запуска бизнеса и других событий.
          </p>
        </div>
        <div className="Cards__cardItem">
          <ClockFill className="Cards__icons" />
          <p>
          <span className="Cards__span"> Ректификация Времени Рождения: </span> точное определение времени рождения для точных прогнозов.
          </p>
        </div>
        <div className="Cards__cardItem">
          <StarFill className="Cards__icons" />
          <p>
          <span className="Cards__span"> Раскройте Таланты: </span> oпределите и развивайте свои природные способности.
          </p>
        </div>
        <div className="Cards__cardItem">
          <LifePreserver className="Cards__icons" />
          <p>
          <span className="Cards__span"> Путь Жизни и Карма: </span> pаскроем кармические задачи и цель вашей жизни.
          </p>
        </div>
        <div className="Cards__cardItem">
          <ChatFill className="Cards__icons" />
          <p>
          <span className="Cards__span"> Понимание Отношений: </span> исследуйте личные, романтические и финансовые отношения.
          </p>
        </div>
        <div className="Cards__cardItem">
          <CompassFill className="Cards__icons" />
          <p>
          <span className="Cards__span"> Сглаживание Переходов: </span>  планируйте переезды, путешествия и крупные изменения с уверенностью.
          </p>
        </div>
      </div>
      <img src="/img/Curve.svg" alt="curve"></img>
    </div>
  );
}
