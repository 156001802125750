import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {Provider} from 'react-redux'
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import store from './store';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <PayPalScriptProvider deferLoading={ true }>
        <App />
      </PayPalScriptProvider> 
    </Provider>
  </React.StrictMode>,
)
