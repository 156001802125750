import Navbar from './Navbar'
import './Header.css'

export default function Header({ headerDes, description }) {
    return (
        <div className='Header__fullsizeScreen'>
            <Navbar/>
            <div className='Header__column'>
                <div className='Header_headerText'>
                    <h1>{ headerDes }</h1>
                    <p>{ description }</p>
                </div>
                <div className='Header__imgDiv'> 
                    <div className='Header__horoscopeImg'><div className='Header__sunImg'></div></div>
                </div>
            </div>
            <img className='Header__svgImage' src='../img/wavesOpacity.svg' alt="waves"></img>
        </div>
    )
}