import "./SmileyCards.css";
import { Check2Circle } from "react-bootstrap-icons";

export default function SmileyCards() {
  return (
    <div className="smileyCards__background">
      <div className="smileyCards__container">
        <div className="smileyCards__heading">
          <h1>Важно знать</h1>
        </div>
        <div className="smileyCards__row">
          <div className="smileyCards__card">
            <div className="smileyCards__cardHeader">
              <Check2Circle />
            </div>
            <div className="smileyCards__cardBody">
              <h2>Личное Принятие Решений:</h2>
              <p>
                Если вы хотите, чтобы астролог принял решения за вас, то важно
                помнить, что только вы имеете возможность влиять на свою
                собственную жизнь.
              </p>
            </div>
          </div>
          <div className="smileyCards__card">
            <div className="smileyCards__cardHeader">
              <Check2Circle />
            </div>
            <div className="smileyCards__cardBody">
              <h2>Глубокий Подход К Жизни:</h2>
              <p>
                Астрология не сводится к поверхностным предсказаниям, как в
                журналах. Она не утверждает фатальность. Любое событие имеет
                потенциал для положительных или отрицательных
              </p>
            </div>
          </div>
          <div className="smileyCards__card">
            <div className="smileyCards__cardHeader">
              <Check2Circle />
            </div>
            <div className="smileyCards__cardBody">
              <h2>Вы Создаете Свою Реальность:</h2>
              <p>
                Помните, что вы — художник своей судьбы. Даже если есть вера в
                предопределение, ваши решения и действия остаются важными
                факторами в формировании вашей жизни.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="smileyCards__backgroundWave">
        <img src="/img/reviewWave.svg" />
      </div>
    </div>
  );
}
