import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../headerComponents/Navbar";
import "./createOrder.css";
import { toast } from "react-toastify";
import { useCreateOrderMutation } from "../../slices/ordersApiSlice";
import { clearCartItems } from "../../slices/cartSlice";
import Message from "../Message";

export default function CreateOrder() {
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);

  const [createOrder, { isLoading, error }] = useCreateOrderMutation();

  useEffect(() => {
    if (!cart.customerInformation.cityOfBirth) {
      navigate("/information");
    } else if (!cart.paymentmethod) {
      navigate("/payment");
    } else {
      navigate("/placeorder");
    }
  }, [cart.paymentmethod, cart.customerInformation.cityOfBirth, navigate]);

  const dispatch = useDispatch();

  const placeOrderHandler = async () => {
    try {
      const orderItems = cart.cartItems.map((item) => ({
        name: item.title,
        price: item.price,
        product: item._id,
      }));

      const res = await createOrder({
        orderItems: orderItems,
        customerInformation: cart.customerInformation,
        paymentMethod: cart.paymentmethod,
        itemsPrice: cart.itemsPrice,
      }).unwrap();

      dispatch(clearCartItems());
      navigate(`/order/${res._id}`);
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <div className="placeOrderScreen__background">
      <Navbar />
      <div className="placeOrderScreen__container">
        <div className="placeOrderScreen__leftContainer">
          <div className="placeOrderScreen__customerInfo">
            <h2>Информация о вас</h2>
            <p>
              <strong>Дата рождения:</strong>{" "}
              {cart.customerInformation.dateOfBirth}
            </p>
            <p>
              <strong>Время рождения:</strong>{" "}
              {cart.customerInformation.timeOfBirth}
            </p>
            <p>
              <strong>Место рождения:</strong>{" "}
              {cart.customerInformation.cityOfBirth}
            </p>
            <p>
              <strong>Город проживания:</strong>{" "}
              {cart.customerInformation.cityOfResidence}
            </p>
            <p>
              <strong>Э-мейл для связи: </strong>{" "}
              {cart.customerInformation.contactEmail}
            </p>
            <p>
              <strong>Дополнительная информация:</strong>{" "}
              {cart.customerInformation.preference}
            </p>
            <hr></hr>
          </div>

          <div className="placeOrderScreen__paymentMethod">
            <h2>Способ оплаты</h2>
            <p>{cart.paymentmethod}</p>
            <hr></hr>
          </div>

          <div className="placeOrderScreen__cartItems">
            <h2>Консультации</h2>
            {cart.cartItems.length === 0 ? (
              <Message>Ваша корзина пуста</Message>
            ) : (
              cart.cartItems.map((item) => (
                <>
                  <div key={item._id} className="placeOrderScreen__cartItem">
                    <p>
                      <strong>{item.title}</strong>
                    </p>
                    <p> £{item.price}</p>
                  </div>
                  <hr></hr>
                </>
              ))
            )}
          </div>
        </div>

        <div className="placeOrderScreen__rightContainer">
          <div className="placeOrderScreen__summaryButton">
            <div className="placeOrderScreen__orderSummary">
              <h2>сумма заказа</h2>
              <p>
                <strong>Всего kонсультаций:</strong> {cart.cartItems.length}
              </p>
              <p>£{cart.itemsPrice}</p>
            </div>
            <div className="placeOrderScreen__submitError">
              {error && error.data && (
                <Message variant="danger">{error.data.message}</Message>
              )}
            </div>
            <div className="placeOrderScreen__placeOrderButton">
              <button
                disabled={cart.cartItems === 0}
                onClick={placeOrderHandler}
              >
                Заказать
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
