const addDecimals = (num) => {
    return (Math.round(num * 100) /100).toFixed(2);
}


export const updateCart = (state) => {
    // Calculate items price
    state.itemsPrice = addDecimals(state.cartItems.reduce((acc, item) => acc + item.price, 0));

    localStorage.setItem('cart', JSON.stringify(state));

    return state;
}