import Navbar from "../headerComponents/Navbar";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Message from '../Message';
import Loader from "../Loader";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useGetOrderDetailsQuery, useGetPayPalClientIdQuery, usePayOrderMutation, useDeliverOrderMutation } from "../../slices/ordersApiSlice";
import './orderDisplay.css';

export default function OrderDisplay() {



    const { id: orderId } = useParams();

    const { data: order, refetch, isLoading, isError } = useGetOrderDetailsQuery(orderId);

    const [payOrder, { isLoading: loadingPay }] = usePayOrderMutation();

    const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();

    const [deliverOrder, {isLoading: loadingDeliver}] = useDeliverOrderMutation();

    const { data: paypal, isLoading: loadingPayPal, error: errorPayPal } = useGetPayPalClientIdQuery();

    const { userInfo } = useSelector((state) => state.auth);




    useEffect(() => {
        if (!errorPayPal && !loadingPayPal && paypal?.clientId) {
            const loadPayPalScript = async () => {
                paypalDispatch({
                    type: 'resetOptions',
                    value: {
                        'client-id': paypal.clientId,
                        currency: 'USD',
                    }
                });
                paypalDispatch({ type: 'setLoadingStatus', value: 'pending' });
            }
            if (order && !order.isPaid) {
                if (!window.paypal) {
                    loadPayPalScript();
                }
            }
        }
    }, [order, paypal, paypalDispatch, loadingPayPal, errorPayPal]);

    function onApprove(data, actions) { 
        return actions.order.capture().then(async function(details) { 
            try {
                await payOrder({orderId, details});
                refetch();
                toast.success('Payment Successful');
            } catch (err) {
                toast.error(err?.data?.message ||  err.message);
            }
         });
    }

    // async function onApproveTest() { 
    //     await payOrder({orderId, details: {payer: {} }});
    //     refetch();
    //     toast.success('Payment Successful');
    // }

    function onError(err) { 
        toast.error(err.message)
    }

    function createOrder(data, actions) { 
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: order.itemsPrice
                    },
                },
            ],
        }).then((orderId) => {
            return orderId;
        })
    }

    console.log(order); // remove after finished

    const deliverOrderHandler = async () => {
        try {
            await deliverOrder(orderId);
            refetch();
            toast.success('Order delivered');
        } catch (err) {
            toast.err(err?.data?.message || err.message);
        }
    }

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <Message variant="danger">Ошибка загрузки заказа</Message>;
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    return (
        <div className="orderDisplay__background">
            <Navbar />
            <div className="orderDisplay__container">
                {order && (
                    <>
                        <div className="orderDisplay__leftSection">
                            <h1>Заказ {order._id}</h1>
                            <div className="orderDisplay__customerInfo">
                                <h2>Информация о вас</h2>
                                <p><span>Время рождения: </span>{order.customerInformation.timeOfBirth}</p>
                                <p><span>Дата рождения: </span>{formatDate(order.customerInformation.dateOfBirth)}</p>
                                <p><span>Место рождения: </span>{order.customerInformation.cityOfBirth}</p>
                                <p><span>Город проживания: </span>{order.customerInformation.cityOfResidence}</p>
                                <p><span>Э-мейл для связи: </span>{order.customerInformation.contactEmail}</p>
                                <p><span> Дополнительная информация: </span>{order.customerInformation.preference}</p>
                                <hr />
                            </div>
                            <div className="orderDisplay__paymentMethod">
                                <h2>Способ оплаты</h2>
                                <p><span>Метод: </span>{order.paymentMethod}</p>
                                {!order.isPaid ? (
                                    <h3 className="orderDisplay__uncompletedStatus orderDisplay__popupMessage">Пожалуйста оплатитe</h3>
                                    ) : (
                                    <h3 className="orderDisplay__completedStatus orderDisplay__popupMessage">Платеж завершен</h3>
                                )}
                                <hr></hr>
                            </div>
                            <div className="orderDisplay__orderDisplay">
                                <h2>Консультации</h2>
                                {order.orderItems.map((item) => (
                                <div key={item._id} className="orderDisplay__productLists">
                                    <p>{item.name}</p>
                                    <p>£{item.price}</p>
                                </div>))}
                                <hr></hr>
                            </div>
                            <div className="orderDisplay__deliveredStatus">
                                <h2>Завершенный</h2>
                                {order.isDelivered ? (
                                    <h3 className="orderDisplay__completedStatus orderDisplay__popupMessage">Завершенный! Пожалуйста, проверьте контактный е-мейл</h3>
                                    ) : (
                                    <h3 className="orderDisplay__uncompletedStatus orderDisplay__popupMessage">Заказ незавершенный, когда выполненный заказ будет отправлен на указанный е-мейл</h3>
                                )}
                                {userInfo && userInfo.isAdmin && order.isPaid && !order.isDelivered && (
                                    <button onClick={deliverOrderHandler}>Отметить как доставленное</button>
                                )}
                            </div>
                        </div>
                        <div className="orderDisplay__rightSection">
                            <h2>сумма заказа</h2>
                            <div className="orderDisplay__summary">
                                <p>Итоговая цена</p>
                                <p>{order.itemsPrice}</p>
                            </div>
                            <div className="orderDisplay__paypal">
                                {!order.isPaid && !userInfo.isAdmin && ( // <-- Add this condition to hide pay buttons for admins
                                    <>
                                        {loadingPay && <Loader />}
                                        {isPending ? (
                                            <Loader />
                                        ) : (
                                            <div>
                                                <div>
                                                    <PayPalButtons createOrder={createOrder} onApprove={onApprove} onError={onError} />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>  
                    </>
                )}
            </div>
        </div>
    );
    
}
