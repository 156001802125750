import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function AdminRoute() {
    const { userInfo } = useSelector((state) => state.auth);

    const isAdmin = userInfo && userInfo.isAdmin;

    return isAdmin ? <Outlet /> : <Navigate to="/login" replace />
}