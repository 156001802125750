import Navbar from "../headerComponents/Navbar";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import { useRegisterMutation } from "../../slices/usersApiSlice";
import { setCredentials } from "../../slices/authSlice";
import { toast } from "react-toastify";

export default function RegisterForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [register, { isLoading }] = useRegisterMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get("redirect") || "/";

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, redirect, navigate]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmpassword) {
      toast.error("Пароли не совпадают");
      return;
    } else {
      try {
        const res = await register({ name, email, password }).unwrap();
        dispatch(setCredentials({ ...res }));
        navigate(redirect);
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <div className="loginForm__background">
      <Navbar />
      <div className="loginForm__formSection">
        <form onSubmit={submitHandler} className="loginForm__form">
          <h1>регистр</h1>
          <p>Имя</p>
          <input
            type="text"
            placeholder="Введите имя
            "
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></input>
          <p>е-мейл</p>
          <input
            type="email"
            placeholder="Введите е-мейл"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <p>Пароль</p>
          <input
            type="password"
            placeholder="Введите пароль
            "
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <p>Подтвердите пароль</p>
          <input
            type="password"
            placeholder="Подтвердите пароль
            "
            value={confirmpassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></input>
          <br></br>
          <button type="submit" disabled={isLoading}>
            регистр
          </button>

          {isLoading && <Loader />}
          <p>
            У вас уже есть аккаунт?{" "}
            <span>
              <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
                логин
              </Link>
            </span>
          </p>
        </form>
      </div>
    </div>
  );
}
