import React, { useState } from "react";
import { Link } from "react-router-dom";
import { List, XLg, CartFill, PersonFill } from "react-bootstrap-icons";
import "./Header.css";
import { useSelector } from "react-redux";

export default function Navbar() {
  const [openMenu, setOpenMenu] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);

  const { cartItems } = useSelector((state) => state.cart);
  console.log(cartItems);
  const reactiveMenu = () => {
    setOpenMenu(!openMenu);
    console.log(openMenu);
  };

  const navigationOptions = [
    {
      id: 0,
      display: "Главная",
      url: "/",
    },
    {
      id: 2,
      display: "Услуги",
      url: "/products",
    },
    {
      id: 1,
      display: "Обо мне",
      url: "/aboutme",
    },
  ];

  const navigationOptionsDisplay = navigationOptions.map((option) => {
    return (
      <li key={option.id}>
        <Link to={option.url}>{option.display}</Link>
      </li>
    );
  });

  return (
    <div className="Navbar__fullnav">
      <div className="Navbar__container">
        <div>
          <Link to="/">
            <h3>Astrolog</h3>
          </Link>
        </div>
        <div>
          <ul className="Navbar__links">{navigationOptionsDisplay}</ul>
        </div>
        <div className="Navbar__socialIcons">
          <div className="Navbar__icon">
            <Link to="/cart">
              <CartFill className="icon" />
              {cartItems.length > 0 && (
                <span className="Navbar__cartItemCount">
                  {cartItems.length}
                </span>
              )}
            </Link>
            <Link to="/cart">
              <p> Корзина</p>
            </Link>
          </div>

          {userInfo ? (
            <div className="Navbar__icon">
              <Link to="/profile">
                <PersonFill className="icon" />
              </Link>
              <Link to="/profile">
                <p>{userInfo.name}</p>
              </Link>
            </div>
          ) : (
            <div className="Navbar__icon">
              <Link to="/login">
                <PersonFill className="icon" />
              </Link>
              <Link to="/login">
                <p>Войти</p>
              </Link>
            </div>
          )}
        </div>
        <div className="Navbar__toggleBtn" onClick={reactiveMenu}>
          {openMenu ? <XLg /> : <List />}
        </div>
      </div>

      <div className={`Navbar__dropdownMenu ${openMenu ? "open" : ""}`}>
        <div>
          <ul className="Navbar__linksDropdown">{navigationOptionsDisplay}</ul>
        </div>
        <div className="Navbar__dropDownLinkDiv">
          <Link to="/cart">
            <CartFill className="icon" />
          </Link>
          {userInfo ? (
            <>
              <Link to="/profile">
                <PersonFill className="icon" />
              </Link>
            </>
          ) : (
            <>
              <Link to="/login">
                <PersonFill className="icon" />
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
