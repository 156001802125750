import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { MoonStarsFill, CompassFill, BalloonHeartFill, Calendar2CheckFill, AlarmFill } from "react-bootstrap-icons"; // Updated icons
import { addToCart } from "../../slices/cartSlice";
import { useNavigate } from "react-router-dom";

export default function ProductCards({ products }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState("Добавить в корзину"); // Initial button text

  const addToCartHandler = () => {
    dispatch(addToCart({ ...products }));
    setButtonText("Добавлено в корзину"); // Change button text when clicked
    // navigate('/cart');
  };

  const iconMap = {
    MoonStarsFill, // Updated icon
    CompassFill, // Updated icon
    BalloonHeartFill, // Updated icon
    Calendar2CheckFill, // Updated icon
    AlarmFill, // Updated icon
  };

  const IconComponent = iconMap[products.icon];

  const includedItems = products.included?.split("@").filter(item => item.trim() !== "") || [];

  return (
    <div className="ProductCards__card">
      <div className="ProductCards__topCard">
        {IconComponent && <IconComponent className="ProductCards__icon" />}
        <div className="ProductCards__productsInfo">
          <h2>{products.title}</h2>
          {products.time && <h3 className="ProductCards__time">Срок исполнения: {products.time}</h3>}
          <h3>£{products.price}</h3>
        </div>
      </div>
      <hr />
      <p>{products.description}</p>
      <br/>
      {includedItems.length > 0 && (
        <>
          <p>B консультацию входит:</p>
          <ul>
            {includedItems.map((item, index) => (
              <li key={index}>{item.trim()}</li>
            ))}
          </ul>
          <br />
        </>
      )}
      {products.format && (
        <>
          <p>{products.format}</p>
          <br />
        </>
      )}
      <button onClick={addToCartHandler}>{buttonText}</button>
    </div>
  );
}
