import './productPage.css';
import ProductCards from './ProductCards';
import Loader from '../Loader';
import Message from '../Message';
import { useGetProductsQuery } from '../../slices/productsApiSlice';

export default function ProductDisplay(){
    const {data: products, isLoading, error} = useGetProductsQuery();



    return(
        <>
            { isLoading ? (<Loader />) : error ? (<Message variant='danger'>{error?.data?.message || error.error}</Message>) : (            
            <div className="productDisplay__backgroundDiv">
                {products.map((products) => (
                    <div key={products._id}>
                        <ProductCards products={products}/>
                    </div>
                ))}
            </div>) }
        </>

    )
}