import React from "react";
import Navbar from "../headerComponents/Navbar";
import { Link } from "react-router-dom";
import { useGetOrdersQuery } from "../../slices/ordersApiSlice";
import Loader from "../Loader";
import Message from "../Message";
import UsersDisplay from "./UsersDisplay";

export default function DisplayOrders() {
  const { data: orders, isLoading, error } = useGetOrdersQuery();

  return (
    <div className="adminPage__background">
      <Navbar />
      <div className="adminPage__container">
        <div className="adminPage__orders">
          <h1>Услуги</h1>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">
              {error?.data?.message || error.error}
            </Message>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>ПОЛЬЗОВАТЕЛЬ</th>
                  <th>ДАТА</th>
                  <th>СУММА</th>
                  <th>ОПЛАЧЕННЫЙ?</th>
                  <th>ДОСТАВЛЕННЫЙ?</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order._id}>
                    <td>{order._id}</td>
                    <td>{order.user && order.user.name}</td>
                    <td>${order.itemsPrice}</td>
                    <td>
                      {order.isPaid ? <p>Оплаченный</p> : <p>Неоплачиваемый</p>}
                    </td>
                    <td>
                      {order.isDelivered ? (
                        <p>Доставленный</p>
                      ) : (
                        <p>Не доставлен </p>
                      )}
                    </td>
                    <td>
                      <Link to={`/order/${order._id}`}>
                        <button>детали</button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <UsersDisplay />
      </div>
    </div>
  );
}
