import { Instagram, Facebook, Telegram } from "react-bootstrap-icons";
import './footer.css';

export default function Footer() {
    return (
        <div className="Footer_container">
            <div className="Footer__infoContainer">
                <div className="Footer_socialIcons">
                    <a href="https://www.instagram.com/astr_olg/" target="_blank" rel="noopener noreferrer">
                        <Instagram className="footer__icon" />
                    </a>
                    <Facebook className="footer__icon" />
                    <Telegram className="footer__icon" />
                </div>
                <div className="Footer_moreInfo">
                    <p>Astrolog <span>&copy;</span>{new Date().getFullYear()}</p>
                </div>
            </div>
        </div>
    );
}
