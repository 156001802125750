import { removeFromCart } from "../../slices/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { TrashFill, Instagram } from "react-bootstrap-icons";
import Navbar from "../headerComponents/Navbar";
import "./cartPage.css";
import { useNavigate } from "react-router-dom";

export default function CartItem() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const itemsPrice = useSelector((state) => state.cart.itemsPrice);

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const removeFromCartHandler = async (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    navigate("/login?redirect=/information");
  };

  return (
    <div className="cartPage__backgroundColour">
      <Navbar />
      <h1>Корзина</h1>
      <div className="cartPage__cartBackground">
        {cartItems.length === 0 ? (
          <div className="cartPage__backgroundItemsNone">
            <h3>Ваша корзина пуста</h3>
          </div>
        ) : (
          <div className="cartPage__backgroundContainer">
            <div className="cartPage__items">
              {cartItems.map((item) => (
                <div key={item._id}>
                  {item.icon === "Instagram" && (
                    <Instagram className="cartPage__icon" />
                  )}
                  <h3>{item.title}</h3>
                  <p>
                    £<span>{item.price}</span>
                  </p>
                  <TrashFill
                    className="cartPage__trashIcon"
                    onClick={() => removeFromCartHandler(item._id)}
                  />
                </div>
              ))}
            </div>
            <div className="cartPage__total">
              <h2>Итоговая цена</h2>
              <p>£{itemsPrice}</p>
              <button onClick={checkoutHandler}>
                Перейти к оформлению заказа
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
