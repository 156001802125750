import { useState } from "react";
import { ArrowLeftSquareFill, ArrowRightSquareFill} from "react-bootstrap-icons";

export default function CarouselSlider(){

    const reviewsList = [{
        id: 0,
        imgSrc: '/img/Reviews/review1.png'
    },{
        id:1,
        imgSrc: '/img/Reviews/review2.png'
    },{
        id:2,
        imgSrc: '/img/Reviews/review3.png'
    },{
        id:3,
        imgSrc: '/img/Reviews/review4.png'
    },{
        id:4,
        imgSrc: '/img/Reviews/review5.png'
    },{
        id:5,
        imgSrc: '/img/Reviews/review6.png'
    }]

    const [index, setIndex] = useState(0)

    let CurrentImg = reviewsList[index];

    let hasPrev = index > 0;
    let hasNext = index < reviewsList.length - 1;

    function handlePrevClick(){
        if(hasPrev) {
            setIndex(index -1);
        }
    }

    function handleNextClick(){
        if(hasNext) {
            setIndex(index + 1);
        }
    }


    return (
        <div className="CarouselSlider__container">
            <button className="CarouselSlider__leftSlider  Carousel__slider" onClick={handlePrevClick} disabled={!hasPrev}>
                <ArrowLeftSquareFill/>
            </button>
            <div className="CarouselSlider__imgContainer">
                <img className="CarouselSlider__img" src={CurrentImg.imgSrc} alt="review"></img>
            </div>
            <button className="CarouselSlider__rightSlider  Carousel__slider" onClick={handleNextClick} disabled={!hasNext}>
                <ArrowRightSquareFill/>
            </button>
        </div>
    )
}