import Navbar from "../headerComponents/Navbar";
import Footer from "../frontPage/footerComponents/Footer";
import './About.css'

export default function About() {
  return (
    <div className="about_container">
      <div className="about_img">
        <img src='/img/facePhoto.jpg' />
      </div>
      <div className="about_text">
        <h4>Добро пожаловать!</h4>
        <p>
          Меня зовут Ольга, и я практикующий астролог. Я специализируюсь на
          западной классической астрологии. Я постоянно углубляю свои знания,
          всё это позволяет мне предоставлять более точные и информативные
          астрологические консультации.
        </p>
        <p>
          Астрология — это не предсказание будущего, а мощный инструмент для
          самопознания и понимания своих потребностей, целей и вызовов. Ведь
          каждый из нас имеет уникальную карту небесных влияний, и расшифровывая
          эту карту вместе с клиентами, я помогаю им принимать более осознанные
          решения, найти гармонию и строить более счастливую жизнь. С радостью
          делюсь своими знаниями и инсайтами с теми, кто ищет поддержку и
          направление. С нетерпением жду возможности помочь и вам, чтобы ваши
          звёзды сияли ярче, и ваша жизнь становилась более гармоничной и
          насыщенной.
        </p>
      </div>
    </div>
  );
}
