import Header from "../headerComponents/Header";
import ProductDisplay from "../productPage/ProductDisplay";
import Footer from "../frontPage/footerComponents/Footer";

export default function ProductsPage(){
    return(
        <>
        <Header headerDes="Услуги" description="Астрологическая консультация - это индивидуальный подход к пониманию и анализу вашей уникальной личности и жизненных событий через изучение планет и звезд на момент вашего рождения. "/>
        <ProductDisplay />
        <Footer/>
        </>
)
}