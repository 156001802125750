import Header from "../headerComponents/Header"
import Cards from "../frontPage/Cards"
import Reviews from "../frontPage/Reviews"
import Footer from "../frontPage/footerComponents/Footer"
import '../frontPage/FrontStyles.css'
import SmileyCards from "../frontPage/SmileyCards"

export default function MainPage() {
    return(
        <div className="Front__page">
            <Header headerDes="Astrolog" description= "Астрология, это как волшебная карта для жизни. Она рассказывает, как планеты в небе влияют на нас и какие у нас есть особенности. Это как секретный код, который помогает нам понять, какие дни будут удачными, а какие нужно встретить подготовленными."/>
            <Cards/>
            <SmileyCards/>
            <Reviews />
            <Footer/>
        </div>
    )
}