import Navbar from "../headerComponents/Navbar";
import "./payment.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { savePaymentMethod } from "../../slices/cartSlice";

export default function PaymentScreen() {
  const [paymentmethod, setPaymentMethod] = useState("PayPal");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);
  const { customerInformation } = cart;

  useEffect(() => {
    if (!customerInformation) {
      navigate("/information");
    }
  }, [customerInformation, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentmethod));
    navigate("/placeorder");
  };

  return (
    <div className="paymentScreen__background">
      <Navbar />
      <div className="paymentScreen__form">
        <form onSubmit={submitHandler}>
          <h1>Способ оплаты</h1>
          <h3>Выберите метод</h3>
          <div>
            <input
              type="radio"
              name="payment"
              id="payment"
              value="PayPal"
              onChange={(e) => setPaymentMethod(e.target.value)}
            ></input>
            <label htmlFor="payment">PayPal или кредитная карта</label>
          </div>
          <button>Продолжать</button>
        </form>
      </div>
    </div>
  );
}
